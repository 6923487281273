import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateRecommendLike } from 'api/marketFilter';
import { ToastContext } from 'context/toast';
import { useContext } from 'react';
import { useRecoilValue } from 'recoil';
import { globalStoresFilter } from 'recoil/price';
import { globalSearchKeyword } from 'recoil/search';

import filterKeys from '../Filter/keys';

export default function useUpdateRecommendLike() {
  const { showToast } = useContext(ToastContext);
  const queryClient = useQueryClient();
  const keyword = useRecoilValue(globalSearchKeyword);

  const filter = useRecoilValue(globalStoresFilter);
  const deliveryList =
    filter?.orderType?.map((item) => item.value)?.join(',') || null;

  const category = filter?.product[0]?.category
    ? filter.product[0]?.categoryCode
    : null;

  const itemName = filter?.product[0]?.category
    ? null
    : filter.product[0]?.itemName;

  const latitude = filter?.around[0] ? filter.around[0].lat : null;

  const longitude = filter?.around[0] ? filter.around[0].long : null;

  const marketName =
    filter?.location
      ?.filter((item) => item.type !== 'whole')
      .map((item) => item.marketName)
      ?.join(',') || null;

  const zone =
    filter?.location
      ?.filter((item) => item.type === 'whole')
      .map((item) => item.marketName.replace(' 전체', ''))
      ?.join(',') || null;

  return useMutation(updateRecommendLike, {
    onError: (err, value, context) => {
      queryClient.setQueryData(
        filterKeys.getRecommendStores({
          category,
          deliveryList,
          itemName,
          keyword,
          latitude,
          longitude,
          marketName,
          zone,
        }),
        context.previousData
      );
    },
    onMutate: async (newData) => {
      await queryClient.cancelQueries(
        filterKeys.getRecommendStores({
          category,
          deliveryList,
          itemName,
          keyword,
          latitude,
          longitude,
          marketName,
          zone,
        })
      );

      const previousData = queryClient.getQueryData(
        filterKeys.getRecommendStores({
          category,
          deliveryList,
          itemName,
          keyword,
          latitude,
          longitude,
          marketName,
          zone,
        })
      );

      const targetIndex = [...previousData].findIndex(
        (item) => item.id === newData.targetCode
      );

      const totalNewData = [...previousData];
      totalNewData[targetIndex].favorite =
        totalNewData[targetIndex].favorite === 'off' ? 'on' : 'off';

      queryClient.setQueryData(
        filterKeys.getRecommendStores({
          category,
          deliveryList,
          itemName,
          keyword,
          latitude,
          longitude,
          marketName,
          zone,
        }),
        () => totalNewData
      );

      return { previousData };
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries(
        filterKeys.getRecommendStores({
          category,
          deliveryList,
          itemName,
          keyword,
          latitude,
          longitude,
          marketName,
          zone,
        })
      );
      if (res.doLike) {
        showToast('즐겨찾기에 추가되었습니다.마이페이지에서 확인하세요.');
      } else {
        showToast('즐겨찾기 등록이 해제되었습니다.');
      }
    },
  });
}
